import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


function Cart(props) {

    const navigate = useNavigate();
    const [freeDelivery, setFreeDelivery] = useState(false);
    const [totalProducts, setTotalProducts] = useState(null);
    const [total, setTotal] = useState(null);

    useEffect(() => {
        let total_quantity = 0;
        for (let i = 0; i < props.cart.length; i++) {
            total_quantity += props.cart[i].quantity;
        }
        if (total_quantity == 1 && !props.discountAlertRendered) {
            //Despacho gratis si llevas x o mas
            alert(`¡Despacho gratis si llevas ${process.env.REACT_APP_KILOS_NUMBER_DISCOUNT} o más unidades!`);
            props.setDiscountAlertRendered(true);
        }   

        if (total_quantity >= process.env.REACT_APP_KILOS_NUMBER_DISCOUNT) {
            setFreeDelivery(true);
        } else {
            setFreeDelivery(false);
        }
        calculateTotalPrices(total_quantity);
    }, [props.cart]);

    function calculateTotalPrices (total_quantity) {
        let totalProducts = 0;
        for (let i = 0; i < props.cart.length; i++) {
            totalProducts += props.cart[i].total;
        }
        setTotalProducts(totalProducts);
        if (total_quantity >= process.env.REACT_APP_KILOS_NUMBER_DISCOUNT) {
            setTotal(Number(totalProducts));
        } else {
            setTotal(Number(totalProducts) + Number(process.env.REACT_APP_DELIVERY_FEE));
        }
    }

    function handleClickContinue (event) {
        event.preventDefault();
        let total_quantity = 0;;
        for (let i = 0; i < props.cart.length; i++) {
            total_quantity += props.cart[i].quantity;
        }
        if (total_quantity < 1) {
            alert("No tienes productos seleccionados");
            return;
        } else if (total_quantity < 2) {
            alert("Compra mínima de 2kg, selecciona mas productos");
            return;
        }
        navigate("/date");
    }

    return (
        <div>
            <div className="rounded-t-xl m-2 text-gray-600">
                <p className="font-bold text-xl">Tu compra</p>
                { props.cart.length > 0 ? (
                <div>
                    <div>
                        {props.cart.map((item) =>
                            <p key={item.product.id}>{item.quantity} {item.product.type} de {item.product.name}, Subtotal: ${item.total.toLocaleString().replace(/,/g, '.')}</p>
                        )}
                    </div>
                    <div>
                        <p className="font-bold text-lg">Total Productos: ${totalProducts.toLocaleString().replace(/,/g, '.')}</p>
                    </div>
                    { freeDelivery ? (
                        <div>
                            <p className="font-bold text-lg">Despacho: gratis</p>
                        </div>
                    ) : (
                        <div>
                            <p className="font-bold text-lg">Despacho: ${Number(process.env.REACT_APP_DELIVERY_FEE).toLocaleString().replace(/,/g, '.')}</p>
                        </div>
                    )}
                    <div>
                        <p className="font-bold text-lg">Total a Pagar: ${total.toLocaleString().replace(/,/g, '.')}</p>
                    </div>
                </div>) : (
                    <div>
                        <p className="font-bold text-xl">No hay productos seleccionados</p>
                    </div>
                )}
            </div>
            <div className="h-10 mx-8 text-white font-bold">
                <button onClick={handleClickContinue} className={"w-full h-full" + (props.disabled ? " bg-gray-300" : " bg-gray-700 hover:bg-gray-900")}>CONTINUAR</button>
            </div>
        </div>
    );
}

export default Cart;
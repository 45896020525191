// import React, { useState } from "react";



// function ProductShow (props) {
    
//     const [cantidad, setCantidad] = useState(props.items.find((item) => item.product.id === props.product.id)?.quantity || 0);
//     const [total, setTotal] = useState(0)

//     function handleSum (event) {
//         event.preventDefault();
//         if (cantidad < 20) {
//             setCantidad(cantidad + 1);
//             setTotal(props.product.currentPrice * (cantidad + 1));
//             // get a copy of the cart
//             let cartCopy = [...props.cart];
//             // check if the product is already in the cart
//             let productIndex = cartCopy.findIndex((item) => item.product.id === props.product.id);
//             // if the product is already in the cart, update the quantity and total
//             if (productIndex !== -1) {
//                 cartCopy[productIndex].quantity = cantidad + 1;
//                 cartCopy[productIndex].total = props.product.currentPrice * (cantidad + 1);
//             } else {
//                 // if the product is not in the cart, add it
//                 cartCopy.push({
//                     product: props.product,
//                     quantity: cantidad + 1,
//                     total: props.product.currentPrice * (cantidad + 1)
//                 })
//             }
//             // update the cart
//             props.setCart(cartCopy);
//             props.setItems(cartCopy);
//         }
//     }

//     function handleRes (event) {
//         event.preventDefault();
//         if (cantidad > 0) {
//             setCantidad(cantidad - 1);
//             setTotal(props.product.currentPrice * (cantidad - 1));
//             // get a copy of the cart
//             let cartCopy = [...props.cart];
//             // check if the product is already in the cart
//             let productIndex = cartCopy.findIndex((item) => item.product.id === props.product.id);
//             // if the product is already in the cart, update the quantity and total
//             if (productIndex !== -1) {
//                 cartCopy[productIndex].quantity = cantidad - 1;
//                 cartCopy[productIndex].total = props.product.currentPrice * (cantidad - 1);
//             } else {
//                 // if the product is not in the cart, add it
//                 cartCopy.push({
//                     product: props.product,
//                     quantity: cantidad - 1,
//                     total: props.product.currentPrice * (cantidad - 1)
//                 })
//             }
//             // if the quantity is 0, remove the product from the cart
//             if (cantidad - 1 === 0) {
//                 cartCopy.splice(productIndex, 1);
//             }
//             // update the cart
//             props.setCart(cartCopy);
//             props.setItems(cartCopy);
//         }
//     }

//     return(
//         <div>
//             <div className="flex justify-center">
//                 <div className="border-2 border-gray-200 shadow-xl min-w-[60%] max-w-[60%] md:min-w-[30%] md:max-w-[30%] md:min-h-[40%] md:max-h-[40%] overflow-hidden py-4 px-2">
//                     <div className="rounded-t-xl">
//                         <div className="text-2xl text-center font-bold text-gray-600">
//                             <p className="">{props.product.name}</p>
//                         </div>
//                         <div className="text-xs pb-2 text-gray-600">
//                             <p>${props.product.currentPrice.toLocaleString().replace(/,/g, '.')} x Kg</p>
//                         </div>
//                         <div>
//                                 {/* <img className="w-full object-contain bg-white mb-4" src={props.product.imageUrl} alt={props.product.name} /> */}
//                                 <img className="w-full h-[40%] object-cover bg-white mb-4" src={props.product.imageUrl} alt={props.product.name} />
//                         </div>
//                     </div>           
//                     <div className="grid grid-cols-12">
//                         <div className="col-span-3 rounded-bl-xl text-center">
//                             <button onClick={handleRes} className="w-full h-full text-3xl">-</button>
//                         </div>
//                         <div className="col-span-6 text-center text-xl">
//                             <p>{cantidad} Kg</p>
//                         </div>
//                         <div className="col-span-3 rounded-br-xl text-center">
//                             <button onClick={handleSum} className="w-full h-full text-3xl">+</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default ProductShow;




import React, { useState } from "react";

function ProductShow (props) {
    
    const [cantidad, setCantidad] = useState(props.items.find((item) => item.product.id === props.product.id)?.quantity || 0);
    const [total, setTotal] = useState(0);

    function handleSum (event) {
        event.preventDefault();
        if (cantidad < 20) {
            setCantidad(cantidad + 1);
            setTotal(props.product.currentPrice * (cantidad + 1));
            
            let cartCopy = [...props.cart];
            let productIndex = cartCopy.findIndex((item) => item.product.id === props.product.id);
            
            if (productIndex !== -1) {
                cartCopy[productIndex].quantity = cantidad + 1;
                cartCopy[productIndex].total = props.product.currentPrice * (cantidad + 1);
            } else {
                cartCopy.push({
                    product: props.product,
                    quantity: cantidad + 1,
                    total: props.product.currentPrice * (cantidad + 1)
                });
            }
            
            props.setCart(cartCopy);
            props.setItems(cartCopy);
        }
    }

    function handleRes (event) {
        event.preventDefault();
        if (cantidad > 0) {
            setCantidad(cantidad - 1);
            setTotal(props.product.currentPrice * (cantidad - 1));
            
            let cartCopy = [...props.cart];
            let productIndex = cartCopy.findIndex((item) => item.product.id === props.product.id);
            
            if (productIndex !== -1) {
                cartCopy[productIndex].quantity = cantidad - 1;
                cartCopy[productIndex].total = props.product.currentPrice * (cantidad - 1);
            } else {
                cartCopy.push({
                    product: props.product,
                    quantity: cantidad - 1,
                    total: props.product.currentPrice * (cantidad - 1)
                });
            }
            
            if (cantidad - 1 === 0) {
                cartCopy.splice(productIndex, 1);
            }
            
            props.setCart(cartCopy);
            props.setItems(cartCopy);
        }
    }

    return(
        <div className="flex justify-center">
            <div className="border-2 border-gray-200 shadow-xl w-full h-full md:w-full md:h-full overflow-hidden py-4 px-2 flex flex-col justify-between">
                <div className="rounded-t-xl flex flex-col items-center">
                    <div className="text-2xl font-bold text-gray-600 text-center">
                        <p>{props.product.name}</p>
                    </div>
                    <div className="text-xs pb-2 text-gray-600">
                        <p>${props.product.currentPrice.toLocaleString().replace(/,/g, '.')} x {props.product.type}</p>
                    </div>
                    <div className="h-[140px] w-[140px] flex items-center justify-center">
                        <img className="max-w-full max-h-full object-contain bg-white" src={props.product.imageUrl} alt={props.product.name} />
                    </div>
                </div>           
                <div className="grid grid-cols-12 h-[20%]">
                    <div className="col-span-3 rounded-bl-xl text-center">
                        <button onClick={handleRes} className="w-full h-full text-3xl">-</button>
                    </div>
                    <div className="col-span-6 text-center text-xl flex items-center justify-center">
                        <p>{cantidad} {props.product.type == "Malla (2Kg c/u)" ? "Malla" : props.product.type}</p>
                    </div>
                    <div className="col-span-3 rounded-br-xl text-center">
                        <button onClick={handleSum} className="w-full h-full text-3xl">+</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductShow;

import React, { useContext, useEffect, useState, useRef } from "react";
import CheckoutContext from "../contexts/CheckoutContext";
import { useNavigate } from "react-router-dom";
import ProductsResume from "../components/ProductsResume";
import DateResume from "../components/DateResume";
import ClientDataResume from "../components/ClientDataResume";
import DeliveryDirectionResume from "../components/DeliveryDirectionResume";
import LoadingSpinner from "../components/LoadingSpinner";
import WalletButton from "../components/WalletButton";
import axios from "axios";

function CheckoutInfo () {

  const paymentRef = useRef(null);

  const [launchPayment, setLaunchPayment] = useState(false);
  const [isPreferenceCreated, setIsPreferenceCreated] = useState(false);
  const [isLoadingMePaButton, setIsLoadingMePaButton] = useState(false);
  const [preference, setPreference] = useState({})

  const navigate = useNavigate();

  const { items, deliveryDate, deliveryDirection, clientData, seller } = useContext(CheckoutContext);

  useEffect(() => {
    if (items.length === 0 || !clientData.name || !clientData.lastName || !clientData.phoneNumber || !clientData.email || deliveryDate.length !== 4 || Object.keys(deliveryDirection).length === 0 ) {
        navigate("/");
    }
  } ,[]);

  const handleBuy = async (e) => {
      e.preventDefault();
      setIsLoadingMePaButton(true);
      setLaunchPayment(true);

      // Delay scrolling to ensure the ref element is mounted
      setTimeout(() => {
        paymentRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100); // Add a small delay to ensure rendering finishes

      // envia request al backend para crear la preferencia
      async function createPreference() {
        try {
            console.log("CREATING PREFERENCE");
            const data = {
              items,
              deliveryDate,
              deliveryDirection,
              clientData,
              seller,
              quantityDiscount: process.env.REACT_APP_KILOS_NUMBER_DISCOUNT,
              discountPercentage: process.env.REACT_APP_KILOS_NUMBER_DISCOUNT_PERCENTAGE
            }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}payment/create-preference`, data);
            setPreference(response.data);
            if (response.data.id) {
              setIsPreferenceCreated(true);
            }
        } catch (error) {
            console.log(error);
        }
      }
      createPreference();
  };

  return(
      <div>
        <div className="mt-4 flex flex-col gap-2">
          <div>
            <DateResume deliveryDate={deliveryDate}/>
          </div>
          <div>
            <DeliveryDirectionResume deliveryDirection={deliveryDirection}/>
          </div>
          <div>
            <ClientDataResume clientData={clientData}/>
          </div>
          <div>
            <ProductsResume items={items}/>
          </div>
        </div>
        <div className="text-center mb-10 mt-4 text-white font-bold">
            <button className="bg-gray-700 px-12 hover:bg-gray-900 w-full h-10 disabled:bg-gray-300" disabled={launchPayment} onClick={handleBuy}>IR A PAGAR</button>
        </div>
        <div ref={paymentRef} className="pb-[420px]">
          {launchPayment && isPreferenceCreated ? (
              <div>
                  <WalletButton setIsLoadingMePaButton={setIsLoadingMePaButton} preference={preference} />
              </div>
          ) : null }
          {isLoadingMePaButton ? (
              <div className="text-center">
                <LoadingSpinner/>
              </div>
            ) : null }
        </div>
      </div>
  )
}

export default CheckoutInfo;

// disabled={launchPayment}
import React from "react";

const MainTitle = () => {

    return (
        <div className="sticky top-0 py-1 my-2 bg-orange-300" style={{ backgroundColor: "#4B0000" }}>
            <div className="text-center text-white">
                <span className="font-bold text-[34px] tracking-tight">UVAS PREMIUM</span>
            </div>
        </div>
    );
}

export default MainTitle;
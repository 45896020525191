import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";
import ProductShow from "../components/ProductShow";
import Cart from "../components/Cart";
import MainTitle from "../components/MainTitle";
import LoadingSpinner from "../components/LoadingSpinner";
import WaMeLink from "../components/WaMeLink";

function ProductForm () {

    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);
    //to wait for render all the components
    const [isLoaded, setIsLoaded] = useState(false);
    // const [productSelected, setProductSelected] = useState(null);
    //to know when to block continue button
    const [disabled, setDisabled] = useState(true);

    const [discountAlertRendered, setDiscountAlertRendered] = useState(false);

    const { items, setItems, setDeliveryDate, setDeliveryDirection, setClientData, setSeller } = useContext(CheckoutContext);
    const containerRef = useRef(null);
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);

    useEffect(() => {
        async function getProducts() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}product/active`);
                setProducts(response.data);
                setIsLoaded(true)
            } catch (error) {
                console.log(error);
            }
        }
        getProducts();
    }, []);

    useEffect(() => {
        setItems([]);
        setDeliveryDate([]);
        setDeliveryDirection({});
        setClientData({});
        setSeller({});
    } ,[]);

    useEffect(() => {
        //if total quantity is >= 2, then enable continue button
        let total_quantity = 0;
        for (let i = 0; i < cart.length; i++) {
            total_quantity += cart[i].quantity;
        }
        if (total_quantity >= 2) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [cart]);

    // Scroll to a selected product
    useEffect(() => {
        if (selectedProductIndex !== null) {
            scrollToProduct(selectedProductIndex);
        }
    }, [selectedProductIndex]);

    // Scroll to the middle product on load
    useEffect(() => {
        if (containerRef.current && products.length > 0) {
            const middleIndex = Math.floor(products.length / 2);
            scrollToProduct(middleIndex);
        }
    }, [products]);

    const scrollToProduct = (index) => {
        if (containerRef.current && containerRef.current.children[index]) {
            const containerWidth = containerRef.current.clientWidth;
            const item = containerRef.current.children[index];
            const itemOffset = item.offsetLeft;
            const itemWidth = item.clientWidth;
            containerRef.current.scrollTo({
                left: itemOffset - (containerWidth / 2) + (itemWidth / 2),
                behavior: "smooth",
            });
        }
    };

    

    if (isLoaded) {
        return(
            <>
                <MainTitle />
                <div className="text-center pb-4">
                    <div>
                        <div className="text-center text-2xl text-gray-600 mb-4 font-bold">
                            <p>Agrega tus Productos</p>
                        </div>
                        <div className="flex justify-evenly gap-x-1.5 mb-6 overflow-x-auto whitespace-nowrap px-4" ref={containerRef}>
                            {products.map((product, index) => (
                                <div key={product.id} onClick={() => setSelectedProductIndex(index)}>
                                    <ProductShow product={product} cart={cart} setCart={setCart} setItems={setItems} key={product.id} items={items}/>
                                </div>
                            ))}  
                        </div>
                    </div>
                    <Cart cart={cart} disabled={disabled} setDisabled={disabled} discountAlertRendered={discountAlertRendered} setDiscountAlertRendered={setDiscountAlertRendered}/>
                </div>
                <WaMeLink />
            </>
        )
    } else {
        return(
            <div className="mt-72">
                <LoadingSpinner/>
            </div>
        );
    }
}

export default ProductForm;